import { dashboard } from './dashboard';
import { dispatcher } from './dispatcher';
import { admin } from './admin';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [
        dashboard,
        dispatcher,
        admin,
        ]
};

export default menuItems;
