// assets
import { IconDashboard, IconDeviceAnalytics, IconUser, IconBuildingHospital, IconAmbulance, IconMap2, IconMapPin, IconWorld, IconPackages, IconCar, IconList, IconUsers, IconReportMoney  } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconUser: IconUser,
    IconBuildingHospital: IconBuildingHospital,
    IconAmbulance: IconAmbulance,
    IconMap2: IconMap2,
    IconMapPin:IconMapPin,
    IconWorld: IconWorld,
    IconDeviceAnalytics,
    IconPackages:IconPackages,
    IconCar:IconCar,
    IconList: IconList,
    IconUsers: IconUsers,
    IconReportMoney: IconReportMoney,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard/default',
            icon: icons['IconDashboard'],
            breadcrumbs: false,
            roles: ['dispatcher', 'admin'],
        },
        {
            id: 'user-list',
            title: 'Users',
            type: 'item',
            url: '/user-list',
            icon: icons['IconUser'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'lab-list',
            title: 'Hospitals',
            type: 'item',
            url: '/hospital/list',
            icon: icons['IconBuildingHospital'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'vehicle-type-list',
            title: 'Vehicle Type',
            type: 'item',
            url: '/vehicle-type/list',
            icon: icons['IconAmbulance'],
            breadcrumbs: false,
            roles: ['admin'],
        },
        {
            id: 'location-list',
            title: 'Location',
            type: 'collapse',
            icon: icons['IconMapPin'],
            roles: ['admin'],
            children: [
                {
                    id: 'country-list',
                    title: 'Country',
                    type: 'item',
                    url: '/country/list',
                    icon: icons['IconWorld'],
                    breadcrumbs: false,
                },
                {
                    id: 'region-list',
                    title: 'Region',
                    type: 'item',
                    url: '/region/list',
                    icon: icons['IconMap2'],
                    breadcrumbs: false
                },
            ],
        },
        {
            id: 'withdrawal-list',
            title: 'Withdrawal',
            type: 'item',
            url: '/withdrawal/list',
            icon: icons['IconReportMoney'],
            breadcrumbs: false,
            roles: ['admin','dispatcher'],
        },
        
    ]
};
