import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));
const AIToolPage = Loadable(lazy(()=> import('../views/ai-tool')));
const ProfilePage = Loadable(lazy(() => import('../views/profile')));
// User Routes
const UserListPage = Loadable(lazy(() => import('../views/user')));
const UserCreatePage = Loadable(lazy(() => import('../views/user/create')));

// Hospital Routes
const HospitalListPage = Loadable(lazy(() => import('../views/lab/list')));
const HospitalCreatePage = Loadable(lazy(() => import('../views/lab/create')));

// VehicleType Routes
const VehicleTypeListPage = Loadable(lazy(() => import('../views/vehicle-type/list')));
const VehicleTypeCreatePage = Loadable(lazy(() => import('../views/vehicle-type/create')));

// Dispatcher Routes
const DispatcherListPage = Loadable(lazy(() => import('../views/dispatcher/list')));
const DispatcherCreatePage = Loadable(lazy(() => import('../views/dispatcher/create')));

// Dispatcher Staff Routes
const DispatcherStaffListPage = Loadable(lazy(() => import('../views/dispatch-staff/list')));
const DispatcherStaffCreatePage = Loadable(lazy(() => import('../views/dispatch-staff/create')));

// Driver Routes
const DriverListPage = Loadable(lazy(() => import('../views/driver/list')));
const DriverCreatePage = Loadable(lazy(() => import('../views/driver/create')));

// Withdrawal Routes
const WithdrawalListPage = Loadable(lazy(() => import('../views/withdrawal/list')));
const WithdrawalViewPage = Loadable(lazy(() => import('../views/withdrawal/view')));

// Region Routes
const RegionListPage = Loadable(lazy(() => import('../views/region/list')));
const RegionCreatePage = Loadable(lazy(() => import('../views/region/create')));

// Country Routes
const CountryListPage = Loadable(lazy(() => import('../views/country/list')));
const CountryCreatePage = Loadable(lazy(() => import('../views/country/create')));

// Booking Routes
const BookingListPage = Loadable(lazy(() => import('../views/booking/list')));
const BookingViewPage = Loadable(lazy(() => import('../views/booking/view')));

// Home Routes
const HomeListPage = Loadable(lazy(() => import('../views/home/list')));
const HomeViewPage = Loadable(lazy(() => import('../views/home/view')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));

// Pending Booking Routes
const PendingBookingListPage = Loadable(lazy(() => import('../views/pending-booking/list')));
const PendingBookingViewPage = Loadable(lazy(() => import('../views/pending-booking/view')));

// Confirm Booking Routes
const ConfirmBookingListPage = Loadable(lazy(() => import('../views/confirm-booking/list')));
const ConfirmBookingViewPage = Loadable(lazy(() => import('../views/confirm-booking/view')));

// Ongoing Booking Routes
const OngoingBookingListPage = Loadable(lazy(() => import('../views/ongoing-booking/list')));
const OngoingBookingViewPage = Loadable(lazy(() => import('../views/ongoing-booking/view')));

// Completed Booking Routes
const CompletedBookingListPage = Loadable(lazy(() => import('../views/completed-booking/list')));
const CompletedBookingViewPage = Loadable(lazy(() => import('../views/completed-booking/view')));

// Cancelled Booking Routes
const CancelledBookingListPage = Loadable(lazy(() => import('../views/cancelled-booking/list')));
const CancelledBookingViewPage = Loadable(lazy(() => import('../views/cancelled-booking/view')));

// Reports

const BookingReportsPage = Loadable(lazy(() => import('../views/booking-reports/list')));
const WithdrawalReportsPage = Loadable(lazy(() => import('../views/withdrawal-reports/list')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route
            path={[
                '/dashboard/default',
                '/utils/util-typography',
                '/utils/util-color',
                '/utils/util-shadow',
                '/icons/tabler-icons',
                '/icons/material-icons',
                '/sample-page',
                '/ai-tool/default',
                '/profile',
                '/user-list',
                '/user-create',
                '/user-edit/:id',
                '/hospital/*',
                '/vehicle-type/*',
                '/region/*',
                '/country/*',
                '/home/*',
                '/dispatcher/*',
                '/dispatch-staff/*',
                '/driver/*',
                '/withdrawal/*',
                '/booking/*',
                '/pending-booking/*',
                '/confirm-booking/*',
                '/ongoing-booking/*',
                '/completed-booking/*',
                '/cancelled-booking/*',
                '/booking-reports',
                '/withdrawal-reports',
            ]}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path="/dashboard/default" component={DashboardDefault} />

                        <Route path="/utils/util-typography" component={UtilsTypography} />
                        <Route path="/utils/util-color" component={UtilsColor} />
                        <Route path="/utils/util-shadow" component={UtilsShadow} />
                        <Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
                        <Route path="/icons/material-icons" component={UtilsMaterialIcons} />

                        <Route path="/sample-page" component={SamplePage} />
                        <Route path="/ai-tool/default" component={AIToolPage} />
                        <Route path="/profile" component={ProfilePage} />
                        <Route path="/user-list" component={UserListPage} />
                        <Route path="/user-create" component={UserCreatePage} />
                        <Route path="/user-edit/:id" component={UserCreatePage} />

                        <Route path="/hospital/list" component={HospitalListPage} />
                        <Route path="/hospital/create" component={HospitalCreatePage} />
                        <Route path="/hospital/edit/:id" component={HospitalCreatePage} />

                        <Route path="/vehicle-type/list" component={VehicleTypeListPage} />
                        <Route path="/vehicle-type/create" component={VehicleTypeCreatePage} />
                        <Route path="/vehicle-type/edit/:id" component={VehicleTypeCreatePage} />

                        <Route path="/region/list" component={RegionListPage} />
                        <Route path="/region/create" component={RegionCreatePage} />
                        <Route path="/region/edit/:id" component={RegionCreatePage} />

                        <Route path="/country/list" component={CountryListPage} />
                        <Route path="/country/create" component={CountryCreatePage} />
                        <Route path="/country/edit/:id" component={CountryCreatePage} />

                        <Route path="/home/list" component={HomeListPage} />
                        <Route path="/home/view/:id" component={HomeViewPage} />

                        <Route path="/dispatcher/list" component={DispatcherListPage} />
                        <Route path="/dispatcher/create" component={DispatcherCreatePage} />
                        <Route path="/dispatcher/edit/:id" component={DispatcherCreatePage} />

                        <Route path="/dispatch-staff/list" component={DispatcherStaffListPage} />
                        <Route path="/dispatch-staff/create" component={DispatcherStaffCreatePage} />
                        <Route path="/dispatch-staff/edit/:id" component={DispatcherStaffCreatePage} />

                        <Route path="/driver/list" component={DriverListPage} />
                        <Route path="/driver/create" component={DriverCreatePage} />
                        <Route path="/driver/edit/:id" component={DriverCreatePage} />

                        <Route path="/withdrawal/list" component={WithdrawalListPage} />
                        <Route path="/withdrawal/view/:id" component={WithdrawalViewPage} />

                        <Route path="/booking/list" component={BookingListPage} />
                        <Route path="/booking/view/:id" component={BookingViewPage} />

                        <Route path="/pending-booking/list" component={PendingBookingListPage} />
                        <Route path="/pending-booking/view/:id" component={PendingBookingViewPage} />

                        <Route path="/confirm-booking/list" component={ConfirmBookingListPage} />
                        <Route path="/confirm-booking/view/:id" component={ConfirmBookingViewPage} />

                        <Route path="/ongoing-booking/list" component={OngoingBookingListPage} />
                        <Route path="/ongoing-booking/view/:id" component={OngoingBookingViewPage} />

                        <Route path="/completed-booking/list" component={CompletedBookingListPage} />
                        <Route path="/completed-booking/view/:id" component={CompletedBookingViewPage} />

                        <Route path="/cancelled-booking/list" component={CancelledBookingListPage} />
                        <Route path="/cancelled-booking/view/:id" component={CancelledBookingViewPage} />

                        <Route path="/booking-reports" component={BookingReportsPage} />
                        <Route path="/withdrawal-reports" component={WithdrawalReportsPage} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
