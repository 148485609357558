// assets
import { IconReport, IconCheckupList, IconCoins } from '@tabler/icons';

// constant
const icons = {
    IconReport: IconReport,
    IconCoins: IconCoins,
    IconCheckupList: IconCheckupList,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const admin = {
    id: 'admin',
    title: 'Admin',
    type: 'group',
    children: [
        {
            id: 'report-list',
            title: 'Reports',
            type: 'collapse',
            icon: icons['IconReport'],
            roles: ['admin'],
            children: [
                {
                    id: 'booking-reports',
                    title: 'Booking Report',
                    type: 'item',
                    url: '/booking-reports',
                    icon: icons['IconCheckupList'],
                    breadcrumbs: false,
                },
                {
                    id: 'withdrawal-reports',
                    title: 'Withdrawal Report',
                    type: 'item',
                    url: '/withdrawal-reports',
                    icon: icons['IconCoins'],
                    breadcrumbs: false,
                }
            ],
        },
        
    ]
};
